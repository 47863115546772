
import Vue from "vue";
import { authMapGetters } from "@/store/modules/auth";
import { getCompanyLabel } from "../../../helpers/companies";
import { quoteMapState } from "@/store/modules/quote";

export default Vue.extend({
  name: "territory-lookup-select",
  props: {
    territories: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    ...quoteMapState(["editing"]),
    getCompanies(): any {
      const companies =
        this.getCurrentUser && this.getCurrentUser.companiesData
          ? this.getCurrentUser.companiesData
          : [];
      return companies;
    }
  },
  methods: {
    getTerritory(territory: any) {
      const result = territory.territoryInfo.companyTerritories.map(
        ({ companyNumber, terr }: any) =>
          `${getCompanyLabel(companyNumber, "", "quote")} - ${terr}`
      );
      return result;
    },
    getSeacoastTerritories(territory: any) {
      const companyTerritories = territory.territoryInfo.companyTerritories;

      const result = companyTerritories.map(function({
        isSeaCoast,
        companyNumber,
        seaTerr
      }: any) {
        return isSeaCoast
          ? `${getCompanyLabel(companyNumber, "", "quote")} - ${seaTerr}`
          : null;
      });
      return result;
    }
  }
});
