
export interface ITerritory {
  ppc: string;
  terr: string;
  seaTerr: string;
}

import { ErrorWrapper } from "@/services/error_wrapper";
import {
  quoteMapActions,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import TerritoryLookupSelect from "@/views/shared/quotes/TerritoryLookupSelect.vue";
import UnderwriterInfo from "./Components/UnderwriterInfo.vue";
import Vue from "vue";
import { isCompletedStatus } from "@/helpers";
export default Vue.extend({
  name: "territory",
  components: { TerritoryLookupSelect, UnderwriterInfo },
  props: {
    quoteId: {
      type: String,
      required: true
    }
  },
  async created() {
    await this.fetchTerritories();
    if (
      this.editing &&
      this.editing.territoryData &&
      this.editing.territoryData._id
    ) {
      this.territoryId = this.editing.territoryData._id;
    } else if (this.editing && this.editing.territoryId) {
      //TODO: this was to make sure previous quote data that had territoryId
      //continued to work as expected. Please remove this after a month or so
      //from this commit.
      this.territoryId = this.editing.territoryId;
    }

    if (this.lookupResults.length === 1) {
      this.terrChangedHandler(this.lookupResults[0]);
    }

    const isExistingRelevantPPC = this.lookupResults.find((result: any) => {
      return this.editing?.territoryData?.id === result._id;
    });

    this.disablePrimaryBtn =
      this.editing?.territoryData?.territoryInfo?.ppc && isExistingRelevantPPC
        ? false
        : true;
  },
  data() {
    return {
      territory: "",
      territoryId: "",
      lookupResults: [] as any[],
      loading: false,
      disablePrimaryBtn: true,
      loadingText: ""
    };
  },
  methods: {
    ...quoteMapActions([
      "saveTerritoryInformation",
      "getQuoteTerritoryInformation",
      "saveQuotePropertyFields"
    ]),
    ...quoteMapMutations({
      setEdit: "SET_EDIT"
    }),
    async terrChangedHandler(event: any) {
      if (this.quoteHasBeenSubmitted) return;
      this.loading = true;
      this.disablePrimaryBtn = true;

      if (event._id) {
        this.territoryId = event._id;
      } else {
        if (typeof event === "string") {
          this.territoryId = event;
        }
      }
      await this.saveTerritory();
      this.loading = false;
      this.disablePrimaryBtn = false;
    },
    async toolbarSelectItem(event: string): Promise<void> {
      switch (event) {
        case "save": {
          this.saveTerritory();
          break;
        }
        case "next":
          this.$router.push(this.nextUrl);
          break;
        case "cancel":
          this.$router.go(-1);
          break;
      }
    },
    async fetchTerritories(): Promise<void> {
      try {
        this.loadingText = "Fetching territories. Please wait...";
        const res = await this.getQuoteTerritoryInformation(
          this.$route.params.quoteId
        );
        this.lookupResults = res.data;
      } catch (error) {
        //typescript decided to start typing the error
        //caught above as unknown instead of the original any
        //This means we have to do some kind of type narrowing
        //to find out what the error "is" - to be able to perform allowed
        //operations
        if (error instanceof ErrorWrapper) {
          this.disablePrimaryBtn = true;
          this.$appNotifyError(error.message);
        }
        this.$bugSnagClient.notify(error);
      } finally {
        this.loadingText = "";
      }
    },
    async saveTerritory(
      optionalPayload: Record<string, any> | null = null
    ): Promise<void> {
      try {
        this.loadingText = "Saving Territory Information";
        let payload: Record<string, any> = { territoryId: this.territoryId };
        payload = !optionalPayload ? payload : optionalPayload;
        this.loading = true;
        const response = await this.saveTerritoryInformation({
          id: this.$route.params.quoteId,
          payload
        });
        this.disablePrimaryBtn = false;
        this.setEdit(response.data);
      } catch (error) {
        if (error instanceof ErrorWrapper) {
          this.disablePrimaryBtn = true;
          this.$appNotifyError(error.message);
        }
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    showWindInlcussionPrompt(territoryId: string): boolean {
      const territoryData = this.lookupResults.find(
        (result: any) => result._id === territoryId
      );
      const windTerritoryCodes = ["1X", "8X", "9X", "10X"];
      const windAgents = ["gsmi-rock", "sgim-seac", "AtlasAdmin", "frmk"];
      if (territoryData) {
        const { terr, seaTerr } = territoryData.territoryInfo;
        const isWindAgent = windAgents.includes(
          this.$getCurrentUser.agentCode || ""
        );
        const isWindTerritory = windTerritoryCodes.includes(terr);
        if (
          isWindAgent &&
          isWindTerritory &&
          seaTerr &&
          this.$isCurrentUserAdmin
        ) {
          return true;
        }
      }
      return false;
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest", "editing"]),
    primaryTopActionButton(): {
      text: string;
      key: string;
      disabled?: boolean;
      loading?: boolean;
    } | null {
      return {
        text: "Next",
        key: "next",
        loading: this.makingApiRequest || this.loading,
        disabled: this.disablePrimaryBtn
      };
    },
    nextUrl(): string {
      return `/quotes/${this.$route.params.quoteId}/property-rating`;
    },
    previousUrl(): string {
      return `/quotes/${this.$route.params.quoteId}/risk-address`;
    },
    quoteHasBeenSubmitted(): boolean {
      return !!(
        this.editing &&
        isCompletedStatus(this.editing.status) &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasPolicyNumber
      );
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    }
  }
});
